import { Link } from 'gatsby';

const HomeFooter = () => (
  <div data-qa="homepage-footer">
    <div style={{ 'text-align': 'center', padding: '10px 0 20px' }}>
      nupateer.com is written & published by Keith Howell – for more background
      see <Link to="/info/">Info</Link> tab
    </div>
  </div>
);

export default HomeFooter;
