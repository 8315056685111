import styled from 'styled-components';

const LargeRowWrapper = styled.div`
  margin: 20px 0;

  @media (min-width: 576px) {
    display: flex;
  }
`;

export default LargeRowWrapper;
