import PropTypes from 'prop-types';
import { createGlobalStyle } from 'styled-components';

import LargeRowWrapper from './components/LargeRowWrapper';
import HomeLeftColumn from './components/HomeLeftColumn';
import MasterLayout from '../components/MasterLayout/MasterLayout';
import HomeRightColumn from './components/HomeRightColumn';
import HomeFooter from './components/HomeFooter';

const GlobalStyles = createGlobalStyle`
  hr {
    border: 0;
    height: 0;
    border-top: 1px solid #ccc;
  }
`;

const IndexPage = ({ location }) => (
  <MasterLayout location={location}>
    <GlobalStyles />

    <LargeRowWrapper>
      <HomeLeftColumn />
      <HomeRightColumn />
    </LargeRowWrapper>

    <hr />

    <HomeFooter />
  </MasterLayout>
);

IndexPage.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string
  })
};

export default IndexPage;
