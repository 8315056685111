import { Fragment } from 'react';
import { Link } from 'gatsby';

import LargeColumnWrapper from './LargeColumnWrapper';

const Consequences = () => (
  <Fragment>
    <h3 style={{ 'text-align': 'center' }}>
      CONSEQUENCES
      <br /> - what the coming decade might look like if the SNP gets its way on
      independence
    </h3>

    <p>
      <Link to="/articles/consequences">
        <img
          src="/media/articles/three-flags.jpg"
          alt="CONSEQUENCES"
          style={{
            width: '100%',
            'max-width': '250px',
            float: 'right',
            'padding-left': '20px',
            'padding-bottom': '10px'
          }}
        />
      </Link>
    </p>

    <p>
      What follows is an imagined future for Scotland. From 2021 to 2031. A
      decade that doubtless some believe will turn out much better than the
      picture I have set out here. Equally, it could prove to be a lot worse...
      <Link to="/articles/consequences" data-qa="read-more-link">
        Read more
      </Link>
    </p>
    <div style={{ clear: 'both' }}></div>
  </Fragment>
);

const HomeLeftColumn = () => (
  <LargeColumnWrapper data-qa="homepage-left-column">
    <h3 style={{ 'text-align': 'center' }}>THE ’20/’21 SERIES</h3>

    <div>
      <h3>Introduction</h3>

      <p>
        Over the last decade or so of often fractious debate, the views of many
        in Scotland have become increasingly hardened on Scotland’s big question
        - that is, whether we should remain in or leave the UK...
        <Link
          to="/articles/introduction-to-20-21-series"
          data-qa="read-more-link"
        >
          Read more
        </Link>
      </p>
    </div>

    <hr />

    <Consequences />

    <hr />

    <div>
      <h3>Next time...</h3>

      <p>
        The next instalment in the ’20/’21 series of articles will consider some
        of the many questions that Nicola Sturgeon would rather avoid...
      </p>
    </div>
  </LargeColumnWrapper>
);

export default HomeLeftColumn;
